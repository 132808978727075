import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";

export const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "home",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/diseaseDictionary",
        name: "diseaseDictionary",
        component: () => import("@/views/diseaseDictionary/index.vue"),
        meta: {
          title: "病种词典",
        },
      },
      {
        path: "/popularScienceTweets",
        name: "popularScienceTweets",
        component: () => import("@/views/popularScienceTweets/index.vue"),
        meta: {
          title: "科普材料",
        },
      },
      {
        path: "/popularScienceCourseware",
        name: "popularScienceCourseware",
        component: () => import("@/views/popularScienceCourseware/index.vue"),
        meta: {
          title: "科普课件",
        },
      },
      {
        path: "/expertAppointment",
        name: "expertAppointment",
        redirect: "/appointment",
        component: () => import("@/views/expertAppointment/index.vue"),
        meta: {
          title: "专家预约",
        },
        children: [
          {
            path: "/appointment",
            name: "appointment",
            component: () =>
              import("@/views/expertAppointment/appointment.vue"),
            meta: {
              title: "专家预约-首页",
            },
          },
          {
            path: "/expertDetails",
            name: "expertDetails",
            component: () =>
              import("@/views/expertAppointment/expertDetails.vue"),
            meta: {
              title: "专家详情",
            },
          },
          {
            path: "/submission",
            name: "submission",
            component: () => import("@/views/expertAppointment/submission.vue"),
            meta: {
              title: "预约提交",
            },
          },
        ],
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/index.vue"),
        meta: {
          title: "登录",
        },
      },
      {
        path: "/personal",
        name: "personal",
        redirect: "/user",
        component: () => import("@/views/personal/index.vue"),
        meta: {
          title: "个人中心",
        },
        children: [
          {
            path: "/user",
            name: "user",
            component: () => import("@/views/personal/user.vue"),
            meta: {
              title: "个人中心-我的资料",
            },
          },
          {
            path: "/download",
            name: "download",
            component: () => import("@/views/personal/download.vue"),
            meta: {
              title: "个人中心-下载记录",
            },
          },
          {
            path: "/upload",
            name: "upload",
            component: () => import("@/views/personal/upload.vue"),
            meta: {
              title: "个人中心-上传记录",
            },
          },
          {
            path: "/whatsapp",
            name: "whatsapp",
            component: () => import("@/views/personal/whatsapp.vue"),
            meta: {
              title: "个人中心-我的预约",
            },
          },
          {
            path: "/contacts",
            name: "contacts",
            component: () => import("@/views/personal/contacts.vue"),
            meta: {
              title: "个人中心-专家预约",
            },
          },
          {
            path: "/mail",
            name: "mail",
            component: () => import("@/views/personal/mail.vue"),
            meta: {
              title: "个人中心-意见反馈",
            },
          },
        ],
      },
      {
        path: "/uploadFile",
        name: "uploadFile",
        component: () => import("@/views/uploadFile/index.vue"),
        meta: {
          title: "上传文件",
        },
      },
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/search/index.vue"),
        meta: {
          title: "搜索",
        },
      },
      {
        path: "/articleDetails",
        name: "articleDetails",
        component: () => import("@/views/articleDetails/index.vue"),
        meta: {
          title: "文章详情",
        },
      },
    ],
  },
  {
    path: "/preview",
    name: "preview",
    component: () => import("@/views/preview/index.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
